<template>
  <v-col v-if="display" class="mt-3" cols="12">
    <sheet dense depressed light outlined>
      <v-overlay :value="!bValid" absolute color="#FFFFFF">
        <v-alert v-if="sError" dense type="warning" width="100%">
          {{ sError }}
        </v-alert>
      </v-overlay>
      <references-table />
    </sheet>
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";

import ReferencesTable from "@/modules/invoices/components/references/ReferencesTable.vue";
import { InvoiceMovementType } from "@planetadeleste/vue-mc-gw";
import { number, ValidationError } from "yup";

@Component({
  components: { ReferencesTable },
})
export default class InvoiceReferences extends Mixins(InvoiceMixin) {
  @Prop(Boolean) readonly readOnly!: boolean;
  sError: string | null = null;
  bValid = false;

  get display() {
    const arMovementTypeList = [
      InvoiceMovementType.CODE_REFOUND,
      InvoiceMovementType.CODE_DEBIT_NOTE,
      InvoiceMovementType.CODE_RECEIPT,
      InvoiceMovementType.CODE_DEBT,
      InvoiceMovementType.CODE_RECEIPT_CORRECT,
      InvoiceMovementType.CODE_PAY,
      InvoiceMovementType.CODE_CANCEL_DEBT,
      InvoiceMovementType.CODE_DEBT_RYA,
    ];

    if (!arMovementTypeList.includes(this.iMovementType) || !this.customerId) {
      return false;
    }

    if (this.signed && !this.references?.length) {
      return false;
    }

    return true;
  }

  @Watch("customerId")
  async onValidateCustomer() {
    const obSchema = number()
      .required()
      .label(this.$t(this.isPay ? "provider" : "customer") as string);

    try {
      await obSchema.validate(this.customerId);
      this.bValid = true;
      this.sError = null;
      // @ts-ignore
    } catch (err: ValidationError) {
      console.log(err);
      this.bValid = false;
      this.sError = err.errors[0];
    }
  }

  mounted() {
    this.onValidateCustomer();
  }
}
</script>
